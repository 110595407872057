<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo d-flex"
        to="/"
      >
        <b-img
          :src="require('@/assets/images/logo/logo.png')"
          alt="logo"
        />
        <h2 class="brand-text text-primary ml-1 mb-0 align-self-center">
          {{ appTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to {{ appTitle }}
          </b-card-title>
          <b-card-text class="mb-2">
            Please login to your account
          </b-card-text>

          <b-alert
            v-if="isCredentialsShow"
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              
              <p>
                <small class="mr-50"><span class="font-weight-bold">Email: </span>demo@projectx.zone</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Password: </span>BestOfficeManagementTool</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Use this login credentials only for the demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                  vid="email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- loading button -->
              <template v-if="isLoading">
                <b-button
                  variant="primary"
                  block
                  disabled
                >
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  Login
                </b-button>
              </template>
            </b-form>
          </validation-observer>

          <b-alert
          class="mt-2"
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Note:</span> After completing your purchase, please check your email for your login credentials.</small>
              </p>
              
            </div>
            
          </b-alert>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'page-auth-register-v2' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { DASHBOARD_ACCESS } from '@/helpers/permissionsConstant'
import { computed } from '@vue/composition-api'

export default {
  name: 'LoginView',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
      status: '',
      password: '',
      email: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      isLoading: false,
      isCredentialsShow: false,
    }
  },
  watch: {
    isCredentialsShow(newVal) {
      if (newVal) {
        this.email = "demo@projectx.zone";
        this.password = "BestOfficeManagementTool";
      } else {
        this.email = "";
        this.password = "";
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  async created() {
    this.isCredentialsShow = process.env.VUE_APP_DEMO_CREDENTIALS_SHOW;
  },

  methods: {
    async validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            // for login
            const resLogin = await this.$api.post('/api/super-admin/login', {
              email: this.email,
              password: this.password,
            })

            const data = resLogin?.data

            await this.$store.dispatch('superAdminAuthModule/setIsAuthenticated', {
              isAuthenticated: true,
              token: data?.access_token,
              user: data?.user,
            })

            localStorage.setItem("superAdminToken", data?.access_token);
            localStorage.setItem("superAdminUser", JSON.stringify(data?.user));
            const isSuperAdmin = this.$store.getters["superAdminAuthModule/getIsAuthenticated"];
        
            if (isSuperAdmin) {
              window.location.reload();
              window.location.href = '/super-admin-dashboard';
            }
            
          } catch (error) {
            this.isLoading = false

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.loginValidation.setErrors(
                error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
